import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-rates-advt',
  templateUrl: './rates-advt.page.html',
  styleUrls: ['./rates-advt.page.scss'],
})
export class RatesAdvtPage implements OnInit {
  @Input() mainCommId: any;
  imgURL: string;
  constructor(private dataService: DataService) { }

  ngOnInit() {
    if (typeof this.mainCommId !== 'undefined') {

    this.dataService.getJahirath(this.mainCommId, '8').then((img: any) => {
      this.imgURL = img.imageurl;
    });
  }
}

}
