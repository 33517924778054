import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RatesAdvtPageRoutingModule } from './rates-advt-routing.module';

import { RatesAdvtPage } from './rates-advt.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RatesAdvtPageRoutingModule
  ],
  declarations: [RatesAdvtPage],
  exports: [RatesAdvtPage]
})
export class RatesAdvtPageModule {}
